import { Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { addToCart } from '../features/cart/cartSlice';
import { alrtSuccess } from '../utils/common';

function ProductsCard({ product }) {
  const navigate = useNavigate();
  const dispatch = useDispatch((state) => state.cart);
  // console.log(product);
  

  const addCart = (data) => {
    dispatch(addToCart(data));
    alrtSuccess('Product Added to Cart');
  };
  return (
    <Col className=" mb-4">
      <div className="product-image">
        <HashLink className="d-block" to={`/products/${product._id}`}>
          <img
            className="w-100 border-3 bg-white"
            src={product.thumbnailImage}
            alt="product"
            loading="lazy"
          />
        </HashLink>
      </div>
      <div className="product-info">
        <h3>{product.title}</h3>
        <p>
          BDT. Price:{" "}
          {product?.discountPrice && product.discountPrice > 0 ? (
            <>
              <del className="text-secondary">{product.regularPrice}</del>{" "}
              <b>{product.price}</b>/-
            </>
          ) : (
            <>
              <b>{product?.regularPrice}</b>/-
            </>
          )}
        </p>
      </div>
    </Col>
  );
}

export default ProductsCard;
